$(document).ready(function() {


    // Clicking on table row
    $('.property-search__results--table').on('click', 'tr[data-url]:not(.has-properties)', function(e) {
      e.preventDefault();
      window.location = $(this).data('url');
    });

  // Clicking an article table row
    $('article').on('click', 'tr[data-url]', function(e) {
      e.preventDefault();
      window.location = $(this).data('url');
    });
    
    $('.property-search__results--table').on('click', 'tr.has-properties', function(e) {
      e.preventDefault();
      $('tr[id=properties_for_scheme_' + $(this).data('scheme') + ']').toggle().find('.scheme-properties-container').slideToggle();
    });
    
    var map_style = [
                {
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#f5f5f5"
                  }
                ]
                },
                {
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
                },
                {
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#616161"
                  }
                ]
                },
                {
                "elementType": "labels.text.stroke",
                "stylers": [
                  {
                    "color": "#f5f5f5"
                  }
                ]
                },
                {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#bdbdbd"
                  }
                ]
                },
                {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#eeeeee"
                  }
                ]
                },
                {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#757575"
                  }
                ]
                },
                {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#e5e5e5"
                  }
                ]
                },
                {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
                },
                {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#ffffff"
                  }
                ]
                },
                {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#DDDDDD"
                  }
                ]
                },
                {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#757575"
                  }
                ]
                },                
                {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#dadada"
                  }
                ]
                },
                {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#616161"
                  }
                ]
                },
                {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
                },
                {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#e5e5e5"
                  }
                ]
                },
                 {
                "featureType": "transit.station",
                "stylers": [
                  {
                    "visibility": "on"
                  }
                ]
                },
                {
                "featureType": "transit.station.bus",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
                },
                {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#c9c9c9"
                  }
                ]
                },
                {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
                }
            ];
    
    $(window).scroll(function() {    
        if($(window).scrollTop() > 0) {
            $("header.header").addClass("scrolling");
        } else {
            $("header.header").removeClass("scrolling");
        }
    });
    
    $('.hamburger').click(function () {
        $(this).toggleClass('is-active');
        $('.header nav').slideToggle();
        $('.header nav').on('click', '.who-we-are', function() {
            $('.hamburger').toggleClass('is-active');
            $('.header nav').slideToggle();
        });
    });
    
    // $('.who-we-are__hero').on('click', 'a', function(e) {
    //     e.preventDefault();
    //     $('.who-we-are__hero').fadeOut(300);
    //     $('.who-we-are__content').slideDown(500);
    //     $('.who-we-are__content').on('click', '.close', function(e) {
    //         e.preventDefault();
    //         $('.who-we-are__content').slideUp(500);
    //         $('.who-we-are__hero').fadeIn(500);        
    //     });
    // });
    
    $('.our-team').on('click', '.arrow-button.profile', function(e) {
        e.preventDefault();
        var person = $(this).data('person');
        $('.overlay').fadeIn(250);
        $('.popup[data-person=' + person + ']').fadeIn(500);
        $('.popup').on('click', '.close', function(e) {
            e.preventDefault();
            $('.popup').fadeOut(250);
            $('.overlay').fadeOut(500);       
        });
    });
    
    $('.our-clients .client').on('click', 'a[data-company]', function(e) {
        e.preventDefault();
        var company = $(this).data('company');
        $('.overlay').fadeIn(250);
        $('.popup[data-company=' + company + ']').fadeIn(500);
        $('.popup').on('click', '.close', function(e) {
            e.preventDefault();
            $('.popup').fadeOut(250);
            $('.overlay').fadeOut(500);       
        });
    });
    
    $('.overlay').on('click', function() {
        $('.popup').fadeOut(250);
        $('.overlay').fadeOut(500);     
    });
    
    /* Contact us page map */
    function gmap() {
        if($('.panel.map #map').length) {

            var marker;

            var lat = 51.5193751,
                lng = -0.156815,
                point = {lat: lat, lng: lng},
                bounds = new google.maps.LatLngBounds();

            var map = new google.maps.Map(document.getElementById('map'), {
                center: point,
                zoom: 17,
                scrollwheel: false,
                styles: map_style
            });

            marker = new google.maps.Marker({
                position: {lat: parseFloat(lat), lng: parseFloat(lng)},
                map: map,
                title: 'McMullen Real Estate',
                icon: '/images/marker.png',
            });

            bounds.extend(marker.position);

            google.maps.event.addListener(map, 'bounds_changed', function() {
                google.maps.event.trigger(map, 'resize');
            });

        }
    }
    google.maps.event.addDomListener(window, "load", gmap);
    
    // Results map
    if(document.getElementById('results-map')) {
        
        var lat = 51.514483,
            lng = -0.150226,
            point = {lat: lat, lng: lng},
            bounds = new google.maps.LatLngBounds(),
            storage = window.localStorage,
            markers = storage.getItem('markers'),
            marker, mk = new Array(), 
            box, infobox, ib = new Array();
        
        var results_map = new google.maps.Map(document.getElementById('results-map'), {
            center: point,
            zoom: 14,
            maxZoom: 16,
            scrollwheel: false,
            styles: map_style
        });
        
        markers = JSON.parse(markers);
        for(var n in markers) {
            
            marker = new google.maps.Marker({
                position: {lat: parseFloat(markers[n].latitude), lng: parseFloat(markers[n].longitude)},
                map: results_map,
                title: markers[n].title,
                icon: '/images/results_marker.png'
            });
            
            mk[n] = marker;
            
            var addListener = function(i) {
                google.maps.event.addListener(mk[i], 'click', function() {
                    
                    box = document.createElement('div');
                    box.innerHTML = '<a href="' + markers[i].uri + '"><img src="' + markers[i].image + '" alt=""><h3>' + markers[i].title + '</h3><h4>' + markers[i].subtitle + '</h4><h5>' + markers[i].size + '</h5></a>';
                    infobox = new InfoBox({
                        content: box
                    });
                    
                    ib.push(infobox);
                    
                    for(var j=0; j<ib.length; j++) {
                        ib[j].close();
                    }
                    infobox.open(results_map, this);
                    
                });
            }
            addListener(n);
            
            bounds.extend(marker.position);  
            
        }
        
        results_map.fitBounds(bounds);
        
        google.maps.event.addListener(results_map, 'bounds_changed', function() {
            google.maps.event.trigger(results_map, 'resize');
        });

    }
    
    // Property page map
    if ($('#property__map').length) {

        var lat = parseFloat($('#property__map').data('lat')),
            lng = parseFloat($('#property__map').data('lng')),
            point = { lat: lat, lng: lng },
            bounds = new google.maps.LatLngBounds();

        var results_map = new google.maps.Map(document.getElementById('property__map'), {
            center: point,
            zoom: 15,
            scrollwheel: false,
            styles: map_style
        });

        marker = new google.maps.Marker({
            position: { lat: parseFloat(lat), lng: parseFloat(lng) },
            map: results_map,
            title: 'McMullen Real Estate',
            icon: '/images/marker.png',
        });

        bounds.extend(marker.position);

        google.maps.event.addListener(results_map, 'bounds_changed', function () {
            google.maps.event.trigger(results_map, 'resize');
        });

    }
    
    /* Search page */
    $('.property-search form .results-type').on('click', '.nav-link', function() {
        $('input[name=results-type]').val($(this).attr('aria-controls'));
        // $('.sale-rent-wrapper').toggleClass('active');
        $('.property-search form').submit();
    });
    
    $('.property-search form').on('change', 'select', function() {
        $('.property-search form').submit();
    });
    
    $('.search-results__results table').on('click', 'tr[data-href]', function() {
        window.location = $(this).data('href');
    });
    
    $('.home-carousel').bxSlider({
        mode: 'fade',
        auto: true,
        pause: 5000,
        nextText: '',
        prevText: '',
        onSliderLoad: function(){
            $('.home-carousel').css("visibility", "visible");
        }
    });
    
    var settings = function() {
        var mobile_settings = {
            auto: true,
            slideWidth: 180,
            minSlides: 1,
            maxSlides: 3,
            slideMargin: 30,
            pager: false
        };
        var desktop_settings = {
            auto: true,
            slideWidth: 148,
            minSlides: 6,
            maxSlides: 6,
            slideMargin: 30,
            pager: false
        };
        return ($(window).width() < 800) ? mobile_settings : desktop_settings;
    };

    if ($(".clients__carousel")[0]){
      var slider = $('.clients__carousel').bxSlider(settings());
      $(window).resize(function() {
          slider.reloadSlider(settings());
      });
    }
    
    // Smooth scroll on anchor links
    $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').not('[role="tab"]').click(function(event) {
        
        // On-page links
        if(location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {

            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

            // Does a scroll target exist?
            if(target.length) {

                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({ scrollTop: target.offset().top }, 1000);

            }

        }

    });
    
    // Link direct to tabs
    var hash = window.location.hash;
    hash && $('ul.nav a[href="' + hash + '"]').tab('show');
    
});